import { observable, action, makeObservable, runInAction } from 'mobx';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import axios from 'axios';
import {Group} from '../type';
import { storeHelpers } from '@vaettyr/boltcave-client-core';

export default class ArticleGroupStore {
    @observable busy:boolean = false;
    @observable groups:Group[] = [];

    private route:string;

    constructor()
    {
        makeObservable(this);
        this.route = storeHelpers.GetEndpoint("ArticleService");
    }

    @action GetGroups(): Promise<Group[]>
    {
        this.busy = true;
        return new Promise((resolve, reject) => {
            axios.get(`${this.route}api/v1/articlegroup/`, AuthStore.GetConfig())
            .then(response => {
                runInAction(() => {
                    this.groups = (response.data ?? [])
                    .sort((a:{order:number},b:{order:number}) => a.order - b.order);
                });
                resolve( this.groups);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                runInAction(() => { this.busy = false; })
            });
        });
    }

    @action async updateGroup(group:Group):Promise<Group|null> {
        this.busy = true;
        return new Promise((resolve, reject) => {
            if(group.id) {
                const index = this.groups.findIndex(g => g.id === group.id);
                if(index < 0) { throw new Error('invalid group passed for update'); }
                if(group.deleted) {
                    axios.delete(`${this.route}api/v1/articlegroup/` + group.id, AuthStore.GetConfig())
                        .then(() => {
                            runInAction(() => {
                                this.groups = [...this.groups.slice(0, index), ...this.groups.slice(index + 1)];
                            });
                            resolve(null);
                        })
                        .catch( err => reject({error: err.response?.data, group}))
                        .finally(() => {
                            runInAction(() => {
                                this.busy = false;
                            })
                        });
                } else {
                    axios.post(`${this.route}api/v1/articlegroup/`, group, AuthStore.GetConfig())
                        .then( response => {
                            runInAction(() => {
                                this.groups[index] = response.data;
                            });
                            resolve(response.data as Group);
                        })
                        .catch(err => reject({error: err.response?.data, group}))
                        .finally(() => {
                            runInAction(() => {
                                this.busy = false;
                            });
                        });
                }
            } else {
                axios.put(`${this.route}api/v1/articlegroup/`, group, AuthStore.GetConfig())
                    .then(response => {
                        runInAction(() => {
                            this.groups = [...this.groups, response.data];
                        });
                        resolve(response.data as Group);
                    })
                    .catch(err => reject({error: err.response?.data, group}))
                    .finally(() => {
                        runInAction(() => {
                            this.busy = false;
                        });
                    });
            }
        })
    }

    @action deleteGroup() {
        this.busy = true;
        // implement the rest of this later
    }
}