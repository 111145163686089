import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {observer, inject} from 'mobx-react';
import ArticleStore from '../stores/articleStore';
import ArticleGroupStore from '../stores/articleGroupStore';
import {AuthStore, Secured} from '@vaettyr/boltcave-auth-client';
import ArticleGroup from './ArticleGroup';
import Article from './Article';
import EditArticle from './EditArticle';
import {usePrevious, modalService, stringHelpers} from '@vaettyr/boltcave-client-core';
import { Group as ArticleGroupType, Article as ArticleType } from '../type';

type PageProps = {
  articlestore?: ArticleStore,
  articlegroupstore?: ArticleGroupStore,
  authstore?: AuthStore,
  modalservice?: modalService
};

export default inject( 'articlestore', 'articlegroupstore', 'authstore', 'modalservice' )(
  observer( (props:PageProps) => {

    const {
      articlestore: { articles = {}, busy = false, sort = (_g:ArticleGroupType, a: ArticleType[]) => a } = {},
      articlegroupstore: { groups = [] } = {},
      authstore,
      modalservice
    } = props;

    const { pathname = "/" } = useLocation();

    const pathParts = pathname.split('/').filter(x => x.length > 0);
    const groupName = pathParts && pathParts.length > 0 ?
      pathParts[0] :
      ( groups.length > 0 ? groups[0].name : null);
    const articleName = pathParts && pathParts.length > 1 ? pathParts[1] : null;

    const selectedGroup = groupName ? groups.find(g => stringHelpers.prettyEncodeUri(g.name) === groupName) : undefined;
    const allArticles = selectedGroup && groupName ? sort(selectedGroup, (articles[groupName] ?? []).slice()) : [];

    const lastAuthenticated = usePrevious(authstore?.authenticated);

    useEffect(() => {
      const shouldFetchStandard = !busy && groupName && !articles[groupName];
      const shouldFetchInactive = !!authstore?.authenticated && !lastAuthenticated &&
        authstore?.hasPermission("BOOLEAN.ArticleService.CanViewInactive", true) &&
        !!groupName && !articles[groupName]?.some(a => !a.active);
      if(groupName && (shouldFetchStandard || shouldFetchInactive)) {
        props.articlestore?.GetArticles(groupName, shouldFetchInactive);
      }
    }, [groups.length, groupName, busy, authstore?.authenticated]);

    function newArticle() {
      if(selectedGroup) {
        modalservice?.show({
          body: <EditArticle article={{group:selectedGroup}}/>,
          options: {className: "edit-article"},
          key: "edit-article"
        });
      }
    }

    function isEditableGroup() {
      const isEditable = selectedGroup?.single ? allArticles.length < 1 : true;
      return isEditable;
    }

    return (
      <div className="container-page">
        <Secured claims={[
          {key:"ENUM.ArticleService.CanEditArticles", value: "OWN", condition:isEditableGroup},
          {key:"ENUM.ArticleService.CanEditArticles", value: "ALL", condition:isEditableGroup}]}>
          <span className="icon clickable is-right" onClick={newArticle} data-tooltip="Create new Article">
              <i className="fa-solid fa-file-circle-plus"></i>
          </span>
        </Secured>
        { selectedGroup?.single ?
          <Article article={allArticles?.length > 0 ? allArticles[0] : undefined} isSingle/>
          : <ArticleGroup
              articles={allArticles}
              selected={articleName}
              group={selectedGroup}/>
        }
      </div>
    );
  })
);