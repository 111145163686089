import { Article as ArticleType, Group as GroupType } from '../type';
import Article from './Article';
import {stringHelpers, CollapsibleGroup} from '@vaettyr/boltcave-client-core';

type ArticleGroupProps = {
    articles?: ArticleType[],
    group?:GroupType,
    selected?:string|null
}

export default function ArticleGroup ({ articles, group, selected }:ArticleGroupProps) {

    const isSelected = selected ? ((/^\d+$/).test(selected) ? parseInt(selected, 10) : selected) : null;
    const activeArticle = typeof isSelected === 'string' ?
        articles?.findIndex( article => isSelected && stringHelpers.prettyEncodeUri(article.title) === stringHelpers.prettyEncodeUri(isSelected) )
        : ( typeof isSelected === 'number' ?
            articles?.findIndex( article => article.id === isSelected )
            : undefined
        );

    return (
        <CollapsibleGroup initialOpen={activeArticle}>
            {articles?.map(article => {
                return (
                    <Article article={article} key={`article-${article.id}`} group={group}/>
                )
            })}
        </CollapsibleGroup>
    );
}
