import React from 'react';
import ReactDOM from 'react-dom';
import { ImageStore, FileStore } from '@vaettyr/boltcave-media-client';
import Nav from './components/Nav';
import Page from './components/Page';
import {SecureApp} from '@vaettyr/boltcave-auth-client';
import ArticleStore from './stores/articleStore';
import ArticleGroupStore from './stores/articleGroupStore';
import GalleryView from './components/GalleryView';

import "./style/index.scss";

const imageStore = new ImageStore();
const fileStore = new FileStore();
const articleStore = new ArticleStore();
const articleGroupStore = new ArticleGroupStore();

ReactDOM.render(
    <React.StrictMode>
        <SecureApp stores={{articlestore:articleStore, articlegroupstore: articleGroupStore, imagestore:imageStore, filestore:fileStore}}>
            <GalleryView/>
            <Nav/>
            <Page/>
        </SecureApp>
    </React.StrictMode>,
    document.getElementById('root')
  );