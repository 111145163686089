import {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import {ImageStore} from '@vaettyr/boltcave-media-client';
import ImageGallery from 'react-image-gallery';

type GalleryViewProps = {
    imagestore?:ImageStore
}

export default inject('imagestore') (observer (({imagestore}:GalleryViewProps) => {
    const [active, setActive] = useState<boolean>(false);
    const [selected, setSelected] = useState<number>();

    const { images = [] } = imagestore ?? {};

    useEffect(() => {
        window.addEventListener("message", (event:MessageEvent) => {
            const { data : { source, id, articleId, group } = {} } = event;
            if( source === 'gallery-view') {
                imagestore?.fetch({sector:group as string, tags: [`article:${articleId}`]})
                .then(() => {
                    if(imagestore?.images.length > 0) {
                        setSelected(imagestore?.images.findIndex(i => i.id === id) ?? 0);
                        setActive(true);
                    }
                });
            }
        });
    }, []);

    if(!active) { return null; }

    const mappedImages = images.map(i => {
        return {
            original: i.location,
            thumbnail: i.children?.find( t => t.category === 'thumbnail' )?.location,
            fullscreen: i.children?.find( f => f.category === 'fullsize' )?.location
        }
    });

    const closeButton = () => {
        return ( <span className="close" onClick={() => {setActive(false)}}>
            X
        </span> );
    }

    return (
        <div className="gallery-view">
            <ImageGallery items={mappedImages} startIndex={selected} renderCustomControls={closeButton}/>
        </div>
    );
} ));