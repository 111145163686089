import { observable, action, makeObservable, runInAction } from 'mobx';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import axios from 'axios';
import React from 'react';
import { IFrameObject } from 'iframe-resizer-react';
import {Article} from '../type';
import { storeHelpers, stringHelpers } from '@vaettyr/boltcave-client-core';
import {Group as ArticleGroupType, Article as ArticleType} from '../type';

export default class ArticleStore {
    @observable busy:boolean = false; // this could be updated to an array of strings for each active request
    @observable articles:{[group:string]:Article[]} = {};
    @observable loaded:number[] = [];
    @observable viewed:{[key:string]: Date } = {};
    bodies:{[key:number]:string} = []; // not observable on purpose. Get it through an action

    private route:string;
    private storageKey:string = "Viewed";

    constructor()
    {
        makeObservable(this);
        this.route = storeHelpers.GetEndpoint("ArticleService");
        const viewed = localStorage.getItem(this.storageKey);
        if(viewed) {
            const parsed = JSON.parse(viewed);
            this.viewed = Object.keys(parsed)
                .reduce((acc, key) => {
                    return { ...acc, [key]: new Date(parsed[key])};
                }, {})
        }
    }

    @action GetArticles(group:string, force?:boolean)
    {
        if(this.busy) {
            return;
        }
        if(!this.articles[group] || force)
        {
            this.busy = true;
            axios.get(`${this.route}api/v1/article/` + group, AuthStore.GetConfig())
                .then( response => {
                    runInAction(() => {
                        this.articles[group] = response.data
                        .map((a:Article&{created?:string, updated?:string})=> ({...this.parseArticle(a), hasBody:a.body, body:undefined})); // need a better solution than this
                    });
                })
                .catch(err => {
                    this.articles[group] = []; // don't get stuck in a loop
                })
                .finally(() => {
                    runInAction(() => { this.busy = false; } );
                });
        }
    }

    @action async GetBody(id:number, ref?:React.MutableRefObject<IFrameObject|undefined>):Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if(Object.keys(this.bodies).includes(id.toString())) {
                resolve(this.bodies[id]);
            } else {
                window.addEventListener("message", (event:MessageEvent) => {
                    const { data : { source, payload } = {} } = event;
                    if(source === 'get-body') {
                        runInAction(() => { this.bodies[id] = payload; } );
                        resolve(payload);
                    } else {
                        reject("Unknown response recieved");
                    }
                }, {once:true});
                ref?.current?.sendMessage({source:"get-body"});
            }
        });
    }

    @action async SaveArticle(article:Article):Promise<Article> {
        this.busy = true;
        return new Promise((resolve, reject) => {
            const group = stringHelpers.prettyEncodeUri(article.group?.name ?? "") ?? "";
            if(article.id) {
                if(!this.articles[group]) {
                    reject("Invalid group");
                }
                const index = this.articles[group].findIndex(a => a.id === article.id);
                if(index < 0) { reject("Invalid article for update"); }
                axios.post(`${this.route}api/v1/article/`, this.articleToPayload(article), AuthStore.GetConfig())
                    .then(({data}:{data:Article&{created?:string, updated?:string}}) => {
                        const parsed = this.parseArticle(data);
                        runInAction(() => {
                            const newGroup = stringHelpers.prettyEncodeUri(data.group?.name) ?? "";
                            if(newGroup === group) {
                                this.articles[group] = [
                                    ...this.articles[group].slice(0, index),
                                    parsed,
                                    ...this.articles[group].slice(index + 1)
                                ];
                            } else {
                                this.articles[group] = [
                                    ...this.articles[group].slice(0, index),
                                    ...this.articles[group].slice(index + 1)
                                ];
                                this.articles[newGroup] = [...this.articles[newGroup], parsed];
                            }

                        });
                        resolve(parsed);
                    })
                    .catch(err => reject({error: err.response.data}))
                    .finally(() => runInAction(() => { this.busy = false } ) );
            } else {
                axios.put(`${this.route}api/v1/article/`, this.articleToPayload(article), AuthStore.GetConfig())
                    .then(({data}:{data:Article}) => {
                        runInAction(() => {
                            this.articles[group] = this.articles[group] ? [...this.articles[group], data] : [data];
                        });
                        resolve(data);
                    })
                    .catch(err => reject({error: err.response.data}))
                    .finally(() => runInAction(() => { this.busy = false; }));
            }
        });
    }

    @action setLoaded(id:number) {
        if(!this.loaded.includes(id)) {
            this.loaded = [...this.loaded, id];
        }
        this.viewed[id.toString()] = new Date();
        localStorage.setItem(this.storageKey, JSON.stringify(this.viewed));
    }

    @action Unload(id:number) {
        if(this.loaded.includes(id)) {
            const index = this.loaded.indexOf(id);
            this.loaded = [...this.loaded.slice(0, index), ...this.loaded.slice(index + 1)];
        }
        if(Object.keys(this.bodies).includes(id.toString())) {
            delete(this.bodies[id]);
        }
    }

    sort(group: ArticleGroupType, articles:ArticleType[]) {
        const parts = group.sortStyle?.split(".") ?? ["ASC"];
        const direction = parts[0] === 'ASC' ? 1 : -1;
        const property = parts.length > 1 ? (parts[1] === "UD" ? "updated" : "created" ) : "order";
        return articles.sort((a,b) => {
            const leftValue = (property === "order" ? a[property] : a[property]?.getTime()) ?? 0;
            const rightValue =(property === "order" ? b[property] : b[property]?.getTime()) ?? 0;
            return direction * (leftValue - rightValue);
        });
    }

    private parseArticle(article:Article & {created?:string, updated?:string}) : Article {
        return {
            ...article,
            updated: article.updated && article.updated !== null ? new Date(article.updated) : undefined,
            created: article.created && article.created !== null ? new Date(article.created) : undefined
        };
    }

    private articleToPayload(article:Article) {
        return {
            ...article,
            sortOrder:article.order,
            articleGroup:article.group?.id,
            thumbnail:article.thumbnail?.id,
            preview:article.preview?.id,
            skin:article.skin?.id
        }
    }
}