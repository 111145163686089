import {inject, observer} from 'mobx-react';
import IframeResizer, { IFrameObject } from "iframe-resizer-react";
import React, { useEffect, useRef, useState } from "react";
import { stringHelpers, modalService, Collapsible } from '@vaettyr/boltcave-client-core';
import ArticleStore from '../stores/articleStore';
import { Article as ArticleType, Group as GroupType } from '../type';
import { Secured } from '@vaettyr/boltcave-auth-client';
import EditArticle from './EditArticle';
import {useNavigate, useLocation} from 'react-router-dom';

type ArticleProps = {
    article?: ArticleType,
    group?: GroupType,
    articlestore?: ArticleStore,
    modalservice?:modalService,
    isSingle?:boolean,
    openIndex?:number,
    isOpen?:boolean,
    onClick?:() => void,
}

export default inject("articlestore", 'modalservice') ( observer(
    function Article(props:ArticleProps) {
        const { article, group, articlestore, modalservice, onClick, openIndex, isOpen, isSingle } = props;

        const navigate = useNavigate();
        const { pathname = "/" } = useLocation();

        const ref = useRef<IFrameObject>();
        const timeoutRef = useRef<null | number>(null);
        const [loading, setLoading] = useState<boolean>(isOpen ?? false);
        const [reloading, setReloading] = useState<boolean>(false);
        const [loadFailed, setLoadFailed] = useState(false);

        useEffect(()=>{
            if(loading && !timeoutRef.current) {
                timeoutRef.current = window.setTimeout(onTimeout, 5000);
            }
            return () => {
                if(timeoutRef.current) {
                    window.clearTimeout(timeoutRef.current);
                }
            };
        }, [loading]);

        if(!article) { return null; }

        const showDate = group?.sortStyle?.split('.').length ?? 0 > 1;
        const viewed = article.id ? articlestore?.viewed[article?.id.toString()] : undefined;
        const viewStatus = viewed ?
            ( article.updated ? (
                article.updated.getTime() > viewed.getTime() ? "updated" : "viewed"
            ) : "viewed" ) :
            "new";


        function editArticle(event:React.MouseEvent<HTMLButtonElement>):void {
            event.preventDefault();
            event.stopPropagation();
            if(article) {
                articlestore?.GetBody(article.id ?? 0, ref)
                    .then((body:string) => {
                        modalservice?.show({
                            body:<EditArticle article={article} body={body} onSave={reloadArticle}/>,
                            options:{className: "edit-article"},
                            key: "edit-article"
                    });
                });
            }
        }

        const isLoaded = article && articlestore?.loaded.includes(article.id ?? 0);

        function reloadArticle(updatedArticle?:ArticleType) {
            articlestore?.Unload(article?.id as number);
            if(article?.group?.id === updatedArticle?.group?.id) {
                setLoading(true);
                setReloading(true);
                window.setTimeout(() => {
                    setReloading(false);
                }, 1); // so hacky, but it works
            } else {
                const groupName = stringHelpers.prettyEncodeUri(updatedArticle?.group?.name);
                navigate(`/${groupName}`);
            }
        }

        function onSelected():void {
            if(onClick) {
                onClick();
            }

            if(loading) { return; }

            if(!isLoaded) {
                setLoading(true);
            }

            const cleanedName = article?.title ? stringHelpers.prettyEncodeUri(article.title) : article?.id?.toString();
            const matches = pathname.match(/^\/[^\/]+\/(.+)$/);
            const currentName = matches && matches.length > 1 ? matches[1] : null;
            const groupName = stringHelpers.prettyEncodeUri(article?.group?.name);
            navigate(`/${groupName}${cleanedName !== currentName ? '/' + cleanedName : ''}`);
        }

        function onTimeout():void {
            setLoading(false);
            setLoadFailed(true);
            timeoutRef.current = null;
        }

        function onResized(data:any):void {
            if(loading) { setLoading(false); }
            if(article) {
                articlestore?.setLoaded(article.id ?? 0);
                if(timeoutRef.current) {
                    window.clearTimeout(timeoutRef.current);
                    timeoutRef.current = null;
                }
                document.getElementById(`article-${article.title ? stringHelpers.prettyEncodeUri(article.title) : article.id}`)?.scrollIntoView({behavior:'smooth'});
            }
        }

        if(isSingle) {
            return (
                <article className="media single-article" id={`article-${article.title ? stringHelpers.prettyEncodeUri(article.title) : article.id}`}>
                    <Secured claims={[
                        {key:"ENUM.ArticleService.CanEditArticles", value:"ALL"},
                        {key:"ENUM.ArticleService.CanEditArticles", value:"OWN", condition:((user?:{id?:number}) => article.owner?.id === user?.id )}]}>
                        <div className="media-right">
                            <span className="icon clickable" onClick={editArticle} data-tooltip="Edit Article">
                                <i className="fa-solid fa-pen-to-square"></i>
                            </span>
                        </div>
                    </Secured>
                    <div className="media-content">
                        {loading && (<progress className="progress is-primary is-medium" max="100">15%</progress>)}
                        {loadFailed && !reloading && (<p className="has-text-danger">Failed to Load Article</p>)}
                        {!loadFailed && !reloading && (
                            <IframeResizer
                                forwardRef={ref}
                                id={`article-${article.id}`}
                                src={`/api/v1/article/${article.group?.name}/${article.id}`} // don't like this. need to have the route passed in like the stores
                                onResized={onResized}
                                className={loading ? "is-loading" : ""}
                                style={{ width: '1px', minWidth: '100%'}}
                            />
                        )}
                    </div>
                </article>
            );
        } else {
            const headerElement = (
            <>
                {article.thumbnail && (<figure className="media-left">
                    <p className="image article-thumbnail">
                        <img src={article.thumbnail?.location}/>
                    </p>
                </figure>)}
                <div className={`media-content${article.thumbnail?"":" no-thumbnail"}`}>
                    <div className="content">
                        <h1 className="title">{article.title}</h1>
                        <h2 className="subtitle">
                            <span>{article.subtitle}</span>
                            {showDate && (<span>
                                {article.updated ? article.updated.toDateString() : article.created?.toString()}
                            </span>)}
                        </h2>

                    </div>

                </div>
                {isLoaded && (<Secured claims={[
                    {key:"ENUM.ArticleService.CanEditArticles", value:"ALL"},
                    {key:"ENUM.ArticleService.CanEditArticles", value:"OWN", condition:((user?:{id?:number}) => article.owner?.id === user?.id )}]}>
                    <div className="media-right">
                        <span className="icon clickable" onClick={editArticle} data-tooltip="Edit Article">
                            <i className="fa-solid fa-pen-to-square"></i>
                        </span>
                    </div>
                </Secured>)}
            </>);

            return (
                <Collapsible header={headerElement}
                    element="article"
                    className={`media ${viewStatus}`}
                    onClick={onSelected}
                    isOpen={isOpen}
                    openIndex={openIndex}
                    id={`article-${article.title ? stringHelpers.prettyEncodeUri(article.title) : article.id}`}
                    >
                    {loading && (<progress className="progress is-primary is-medium" max="100">15%</progress>)}
                    {loadFailed && !reloading && (<p className="has-text-danger">Failed to Load Article</p>)}
                    {isOpen && !loadFailed && !reloading && (
                        <IframeResizer
                            forwardRef={ref}
                            id={`article-${article.id}`}
                            src={`/api/v1/article/${article.group?.name}/${article.id}`} // don't like this. need to have the route passed in like the stores
                            onResized={onResized}
                            className={loading ? "is-loading" : ""}
                            style={{ width: '1px', minWidth: '100%'}}
                        />
                    )}
                </Collapsible>
            );
        }
    }
))