import { observer, inject } from 'mobx-react';
import {useState, useEffect} from 'react';
import ArticleStore from '../stores/articleStore';
import ArticleGroupStore from '../stores/articleGroupStore';
import { Article } from '../type';
import {AuthStore} from '@vaettyr/boltcave-auth-client';
import { ImageStore, FileStore, media, ImagePicker, ImageSelect} from '@vaettyr/boltcave-media-client'
import { modalService } from '@vaettyr/boltcave-client-core';
import { Formik } from 'formik';
import { TextInput, RichText, NumberInput, Checkbox, Select, stringHelpers } from '@vaettyr/boltcave-client-core';
import {Jodit} from 'jodit';
import VideoPicker, {VideoType} from './VideoPicker';

type PageProps = {
    articlestore?: ArticleStore,
    articlegroupstore?: ArticleGroupStore,
    authstore?: AuthStore,
    modalservice?: modalService,
    imagestore?: ImageStore,
    filestore?: FileStore,
    article: Article,
    body?:string,
    single?:boolean,
    onSave?:((article?:Article) => void)
}

export default
inject('articlestore', 'articlegroupstore', 'authstore', 'modalservice', 'imagestore', 'filestore') (
    observer(
    function EditArticle({ article, body, articlegroupstore, modalservice, articlestore, filestore, onSave }:PageProps) {

        const [currentGroup, setCurrentGroup] = useState(articlegroupstore?.groups?.find(group => article?.group?.id === group.id));

        useEffect(() => {
            setCurrentGroup(articlegroupstore?.groups?.find(group => group.id === article.group?.id));
        }, [article.group]);

        useEffect(() => {
            if(!filestore?.files.skin) {
                filestore?.fetch({}, 'skin');
            }
        }, []);

        const skins = filestore?.files.skin ?? [];

        function updateCurrentGroup(value?:string|number) {
            const id = typeof value === 'string' ? parseInt(value, 10) : value;
            setCurrentGroup(articlegroupstore?.groups?.find(group => group.id === value));
        }

        const richTextOptions = [
            {
                name:"Image",
                tooltip:"Load the Image Picker",
                exec:(editor:Jodit, current:Node, options:{originalEvent:Event}) => {
                    openImagePicker(editor);
                }
            },
            {
                name:"Video",
                tooltip:"Open the Video Picker",
                exec:(editor:Jodit, current:Node, options:{originalEvent:Event}) => {
                    openVideoPicker(editor);
                }
            }
        ]

        function onSubmit(data:Article, actions:{setErrors:(err:any)=>void, setSubmitting:(state:boolean)=>void}) {
            articlestore?.SaveArticle({...data, id: article.id, order:article.order} )
            .then((updatedArticle) => {
                if(onSave) {
                    onSave(updatedArticle);
                }
                modalservice?.hide('edit-article');
            })
            .catch(({err}) => {
                actions.setErrors({formErrors:err});
                actions.setSubmitting(false);
            });
        }

        function openImagePicker (editor:Jodit) {
            modalservice?.show({
                body:<ImagePicker sector={article.group?.name} isModal readonly className="article" onSelect={(item:media) => {
                    const imgObj = document.createElement('img') as HTMLImageElement;
                    imgObj.src = item.location;
                    if(item.description) { imgObj.alt = item.description; }
                    imgObj.className = "gallery";
                    imgObj.id = `image-${item.id}`;
                    editor.s.insertImage(imgObj);
                }}/>,
                options:{className:"article-image-picker"},
                key:"image-picker"
            });
        }

        function openVideoPicker (editor:Jodit) {
            modalservice?.show({
                body:<VideoPicker onSelect={(item:VideoType) => {
                    if(item.preview) {
                        const imgObj = document.createElement('img') as HTMLImageElement;
                        imgObj.src = item.preview as string;
                        imgObj.setAttribute('ta-insert-video', item.url);
                        imgObj.className = "ta-insert-video";
                        editor.s.insertImage(imgObj);
                    } else {
                        const videoFrame = document.createElement("iframe");
                        videoFrame.className = "ta-insert-video";
                        videoFrame.contentEditable = "false";
                        videoFrame.allowFullscreen = true;
                        videoFrame.allow = "autoplay";
                        videoFrame.src = item.url;
                        editor.s.insertNode(videoFrame);
                    }
                }}/>,
                options:{className:"article-video-picker"},
                key:"video-picker"
            });
        }

        function validateTitle(value?:string):Promise<string|null|undefined> {
            return new Promise((resolve, reject) => {
                if(article?.group?.name) {
                    const otherArticle = articlestore?.articles[article.group.name]?.find(a => {
                        return stringHelpers.prettyEncodeUri(value) === stringHelpers.prettyEncodeUri(a.title) && a.id !== article?.id;
                    })
                    if(otherArticle) {
                        resolve("title is too similar to another article");
                    } else {
                        resolve(undefined);
                    }
                }  else {
                    resolve(undefined);
                }
            });
        }

        const initialValues = {
            title:article.title ?? "",
            subtitle:article.subtitle ?? "",
            thumbnail:article.thumbnail,
            preview:article.preview,
            group:article.group,
            skin: article.skin,
            active:article.active,
            body: body ?? ""
        };

        return (
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    errors,
                    dirty
                }) => {
                const { formErrors } = errors as { formErrors: string|string[] };
                return (
                <>
                    <header className="card-header">
                        <p className="card-header-title">Edit Article</p>
                    </header>
                    <div className="card-content">
                        <form onSubmit={handleSubmit}>
                            {formErrors && (<p className="has-text-danger">{Array.isArray(formErrors) ? formErrors.join(',') : formErrors}</p>)}
                            <TextInput name='title' label="Title" required validate={validateTitle}/>
                            <TextInput name="subtitle" label="Subtitle"/>
                            <ImageSelect name="thumbnail" label="Thumbnail" modalProps={{className:"article-image-picker", sector:article.group?.name, selectCategory:'thumbnail', tags:['thumbnail']}}/>
                            <ImageSelect name="preview" label="Preview" modalProps={{className:"article-image-picker", sector:article.group?.name}}/>
                            <Select name="group.id" label="Group" options={articlegroupstore?.groups?.map( g => ({label:g.name, value:g.id}))??[]} required onChange={updateCurrentGroup}/>
                            <NumberInput name="order" label="Sort Order" disabled={!(["ASC","DESC"]).includes(currentGroup?.sortStyle ?? "ASC")}/>
                            <Select name="skin.id" label="Skin" disabled={!skins.length} options={skins.length ? ['', ...skins.map(skin => ({label:skin.name, value:skin.id as number}))] : []}/>
                            <Checkbox name="active" label="Is Active"/>
                            <RichText name="body" options={richTextOptions}/>
                        </form>
                    </div>
                    <footer className="card-footer">
                        <button className="card-footer-item button is-primary"
                            type="button"
                            disabled={isSubmitting || !isValid || !dirty}
                            onClick={() => {handleSubmit()}}>Save</button>
                        <button className="card-footer-item button" onClick={()=>{modalservice?.hide('edit-article')}}>Cancel</button>
                    </footer>
                </>
                )}}
             </Formik>
        )
    }
))