import { Formik, FormikHelpers } from 'formik';
import {inject, observer} from 'mobx-react';
import { Checkbox, Collapsible, Select, TagsInput, TextInput } from '@vaettyr/boltcave-client-core';
import { AuthStore } from '@vaettyr/boltcave-auth-client';
import { modalService } from '@vaettyr/boltcave-client-core';
import ArticleGroupStore from '../stores/articleGroupStore';
import { Group } from '../type';

const sortStyles = [
    {label:"", value:""},
    {label:"Asending", value:"ASC"},
    {label:"Descending", value:"DESC"},
    {label:"Oldest Created", value:"ASC.CD"},
    {label:"Newest Created", value:"DESC.CD"},
    {label:"Oldest Updated", value:"ASC.UD"},
    {label:"Newest Updated", value:"DESC.UD"}
]

type EditGroupsProps = {
    articlegroupstore?:ArticleGroupStore,
    authstore?:AuthStore,
    modalservice?:modalService
}

export default inject('articlestore', 'articlegroupstore', 'authstore', 'modalservice')(observer(
    function EditGroups({articlegroupstore, modalservice}:EditGroupsProps) {

        const { groups = [] } = articlegroupstore ?? {};

        const viewGroups = groups.filter(g => !!g.public);

        const isNullOrUndefined = (item:any) => {
            return item === undefined || item === null;
        }

        const fuzzyEquals = (a:any, b:any) => {
            return (isNullOrUndefined(a) ? "" : a) === (isNullOrUndefined(b) ? "" : b);
        }

        const isDirty = (updated:Group, original?:Group) => {
            return !original ||
                !fuzzyEquals(original.name, updated.name)||
                !fuzzyEquals(original.sortStyle,updated.sortStyle) ||
                !fuzzyEquals(original.public, updated.public) ||
                !fuzzyEquals(original.order, updated.order) ||
                updated.deleted;
        }

        function onSubmit(values:{groups:Group[]}, actions:FormikHelpers<{groups:Group[]}>) {
            const dirtyGroups = values.groups.filter( g => {
                const sourceGroup = viewGroups.find(v => v.id === g.id );
                return isDirty(g, sourceGroup);
            });
            Promise.all( dirtyGroups.map( g => {
                return articlegroupstore?.updateGroup(g);
            }))
            .then(() => {
                modalservice?.hide('edit-groups');
            })
            .catch(({error, group}:{error?:string[], group:Group}) => {
                const errIndex = values.groups.findIndex(g => g.id === group.id || g.name === group.name);
                const errors = new Array(values.groups.length);
                errors[errIndex] = {name: (error && error.join) ? error?.join(',') ?? "Unknown error" : error};
                actions.setErrors({groups: errors});
                actions.setSubmitting(false);
            })
        }

        const startValues = {groups: viewGroups.map( group => {
            return {
                id: group.id,
                order: group.order,
                name: group.name ?? "",
                sortStyle: group.sortStyle ?? "",
                single: isNullOrUndefined(group.single) ? false : group.single,
                public: isNullOrUndefined(group.public) ? true : group.public,
                sites: group.sites ?? []
            }
        })}

        return (
            <Formik initialValues={startValues} onSubmit={onSubmit}>
                {({
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    dirty,
                    setFieldValue,
                    setFieldTouched,
                    initialValues,
                    values
                }) => {
                    function removeGroup(index:number) {
                        return (e:React.MouseEvent) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const isNewGroup = !values.groups[index].id;
                            if(isNewGroup) {
                                setFieldValue('groups', [...values.groups.slice(0, index), ...values.groups.slice(index + 1)], true);
                            } else {
                                const isDeleted = !!values.groups[index].deleted;
                                setFieldValue(`groups.${index}.deleted`, !isDeleted);
                                setFieldTouched(`groups.${index}.deleted`, isDeleted, true);
                            }
                        }
                    }

                    function addGroup(e:React.MouseEvent) {
                        e.preventDefault();
                        e.stopPropagation();
                        const maxOrder = values.groups.reduce((max, group) => group.order > max ? group.order : max, -1);
                        const newGroup = {
                            order: maxOrder+ 1,
                            name:"",
                            sortStyle:"",
                            single:false,
                            public:true,
                            sites:[window.location.hostname]
                        }
                        setFieldValue('groups', [...values.groups, newGroup], true);
                    }

                    return (
                        <>
                            <header className="card-header">
                                <p className="card-header-title">Edit Groups</p>
                            </header>
                            <div className="card-content">
                                <form onSubmit={handleSubmit}>
                                    {values.groups.map( (g,i) => {
                                        const groupSortOptions = values.groups[i].single ? [] : sortStyles;
                                        const label = initialValues.groups[i]?.name ?? "New Group";
                                        const isDisabled = g.deleted || isSubmitting;
                                        return (
                                            <Collapsible initialOpen={!g.id} header={<div className="panel-header">
                                                <label>{label}</label>
                                                <button className="delete" onClick={removeGroup(i)}></button>
                                            </div>} className={`panel-block${g.deleted?' is-deleted':''}`} key={`group-${g.id}`}>
                                                <TextInput name={`groups.${i}.name`} label="Name" disabled={isDisabled} required/>
                                                <Checkbox name={`groups.${i}.single`} label="Single" disabled={isDisabled}/>
                                                <Select name={`groups.${i}.sortStyle`} label="Sort Style" options={groupSortOptions} disabled={values.groups[i].single || isDisabled}/>
                                                <Checkbox name={`groups.${i}.public`} label="Public" disabled={isDisabled}/>
                                                <TagsInput name={`groups.${i}.sites`} label="Sites" disabled={isDisabled}/>
                                            </Collapsible>
                                            )
                                        }
                                    )}
                                </form>
                            </div>
                            <footer className="card-footer">
                                <button className="card-footer-item button" onClick={addGroup}>New Group</button>
                                <button className="card-footer-item button is-primary"
                                    type="button"
                                    disabled={isSubmitting || !isValid || !dirty}
                                    onClick={() => {handleSubmit()}}>Save</button>
                                <button className="card-footer-item button" onClick={()=>{modalservice?.hide('edit-groups')}}>Cancel</button>
                            </footer>
                        </>
                    )
                }}
            </Formik>
        );
    }
))