import { observer, inject } from 'mobx-react';
import { modalService } from '@vaettyr/boltcave-client-core';
import { Formik } from 'formik';
import { TextInput, Checkbox } from '@vaettyr/boltcave-client-core';

export type VideoType = {
    url:string,
    preview:boolean|string
}

type VideoPickerProps = {
    modalservice?:modalService,
    className?:string,
    onSelect:((item:VideoType) => any),
}

export default inject('modalservice')
(observer(({ modalservice, className = "default", onSelect }:VideoPickerProps) => {

    function handleSelect(values:VideoType) {
        if(values.preview) {
            try {
                const parsed = new URL(values.url);
                let matches:RegExpMatchArray | null = null;
                switch(parsed.host) {
                    case "www.youtbe.com":
                        matches = parsed.pathname.match(/\/embed\/(.+)/);
                        if(matches && matches.length > 1) {
                            values.preview = `https://img.youtube.com/vi/${matches[1]}/hqdefault.jpg`;
                        }
                        break;
                    case "youtu.be":
                        matches = parsed.pathname.match(/\/(.+)/);
                        if(matches && matches.length > 1) {
                            values.preview = `https://img.youtube.com/vi/${matches[1]}/hqdefault.jpg`;
                            values.url = `https://www.youtube.com/embed/${matches[1]}`;
                        }
                        break;
                    default:
                        values.preview = false;
                }
            } catch {
                values.preview = false;
            }
        }
        onSelect(values);
        modalservice?.hide('video-picker');
    }

    function onCancel() {
        modalservice?.hide("video-picker");
    }

    async function validateUrl(url?:string) :Promise<string|null|undefined> {
        return new Promise((resolve, reject) => {
            // make sure it's a youtube link
            if(!url || url === undefined ) {
                resolve("Url is required");
            } else {
                try {
                    const parsed = new URL(url);
                    let err;
                    switch(parsed.host) {
                        case "www.youtbe.com":
                            if(!(/^\/embed\/.+$/).test(parsed.pathname)) {
                                err ="Invalidly formatted url";
                            }
                            break;
                        case "youtu.be":
                            if(!(/^\/.+$/).test(parsed.pathname)) {
                                err ="Invalidly formatted url";
                            }
                            break;
                        default:
                            err = "Invalid or non-youtube url";
                            break;
                    }
                    resolve(err);
                }
                catch {
                    resolve("input is not a valid Url");
                }
            }
        });
    }

    return (
        <Formik initialValues={{url:"", preview:true}} onSubmit={handleSelect}>
            {({
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    errors,
                    dirty
                }) => {
                const { formErrors } = errors as { formErrors: string|string[] };
                return (
                    <>
                        <header className="card-header">
                            <p className="card-header-title">Select Video</p>
                        </header>
                        <div className={`video-picker card-content ${className}`}>
                            <TextInput name="url" label="Url" validate={validateUrl}/>
                            <Checkbox name="preview" label="Show Preview"/>
                        </div>
                        <footer className="card-footer">
                            <button type="button" className="card-footer-item button is-primary" disabled={!isValid || isSubmitting || !dirty} onClick={() => {handleSubmit()}}>Select</button>
                            <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
                        </footer>
                    </>
                )}}
        </Formik>
    );
}));